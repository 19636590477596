export default [{
  path: '/dialer/destinations',
  name: 'dialer-destinations',
  component: () => import('@/views/apps/dialer/destinations/index.vue'),
},
{
  path: '/dialer/destinations/:id',
  name: 'dialer-destinations-save',
  component: () => import('@/views/apps/dialer/destinations/destiny.vue'),
},
{
  path: '/dialer/contacts',
  name: 'dialer-contacts',
  component: () => import('@/views/apps/dialer/contacts/imports/index.vue')
},
{
  path: '/dialer/contacts/import/:id',
  name: 'dialer-contacts-import-save',
  component: () => import('@/views/apps/dialer/contacts/imports/contact-import-join.vue'),
},
{
  path: '/dialer/contacts/import/list/:id',
  name: 'dialer-contacts-import-list-list',
  component: () => import('@/views/apps/dialer/contacts/imports/contact-list.vue'),
},
{
  path: '/dialer/blacklist',
  name: 'dialer-blacklist',
  component: () => import('@/views/apps/dialer/blacklist/index.vue'),
},
{
  path: '/dialer/blacklist/:id',
  name: 'dialer-blacklist-save',
  component: () => import('@/views/apps/dialer/blacklist/blacklist.vue'),
},
{
  path: '/dialer/opening-hours',
  name: 'dialer-opening-hours',
  component: () => import('@/views/apps/dialer/opening-hours/index.vue'),
}, {
  path: '/dialer/opening-hours/:id',
  name: 'dialer-opening-hours-save',
  component: () => import('@/views/apps/dialer/opening-hours/opening-hours.vue'),
},
{
  path: '/dialer/campaign-parameters',
  name: 'dialer-campaign-parameters',
  component: () => import('@/views/apps/dialer/campaign-parameters/index.vue'),
}, {
  path: '/dialer/campaign-parameters/:id',
  name: 'dialer-campaign-parameters-save',
  component: () => import('@/views/apps/dialer/campaign-parameters/campaign-parameters.vue'),
},
{
  path: '/dialer/campaign',
  name: 'dialer-campaign',
  component: () => import('@/views/apps/dialer/campaign/index.vue'),
}, {
  path: '/dialer/campaign/:id',
  name: 'dialer-campaign-save',
  component: () => import('@/views/apps/dialer/campaign/campaign.vue'),
}]