export default {
  namespaced: true,
  state: {
    is_transmission: false,
  },
  getters: {},
  mutations: {
    CHANGE_TRANSMISSION_VIEW(state, _payload) {
      state.is_transmission = !state.is_transmission;
    }
  },
  actions: {}
};