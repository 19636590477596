export default [{
  title: 'Gravações',
  tagVariant: 'light-warning',
  icon: "UploadIcon",
  child: [
    {
      title: 'Gravações',
      route: 'audiovisual-upload-list',
      roles: ['permission.audiovisual.demands.upload.midia.view']
    },
    {
      title: 'Pend. de Edição',
      route: 'audiovisual-recording-edit-list',
      roles: ['permission.audiovisual.edition.view']
    },
    {
      title: 'Validar Upload',
      route: 'audiovisual-validate-upload',
      roles: ['permission.audiovisual.validate.view']
    }
  ]
},
{
  title: 'Material Pendente',
  route: 'audiovisual-recording-pending-list',
  icon: "AlertOctagonIcon",
  roles: ['permission.audiovisual.recording.media.pending.view']
}]