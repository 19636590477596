// questões > vinculos
export default {
    namespaced: true,
    state: {
      questionID: null,
      appointmentID: null,
      videoName: null,
      teacherSelected: null,
      institutionSelected: null,
      registrationDate: null,
      onlySuggestion: false,
      hasInstitution: false
    },
    getters: {},
    mutations: {
      UPDATE_QUESTION_ID(state, payload) {
        state.questionID = payload;
      },
      UPDATE_APPOINTMENT_ID(state, payload) {
        state.appointmentID = payload;
      },
      UPDATE_VIDEO_NAME(state, payload) {
        state.videoName = payload;
      },
      UPDATE_SELECTED_TEACHER(state, payload) {
        state.teacherSelected = payload;
      },
      UPDATE_SELECTED_INSTITUTION(state, payload) {
        state.institutionSelected = payload;
      },
      UPDATE_REGISTRATION_DATE(state, payload) {
        state.registrationDate = payload;
      },
      UPDATE_ONLY_SUGGESTION(state, payload) {
        state.onlySuggestion = !state.onlySuggestion;
      },
      UPDATE_HAS_INSTITUTION(state, payload) {
        state.hasInstitution = !state.hasInstitution;
      }
    },
    actions: {}
  };
  