import demandsaudiovisual from './demands-audiovisual'
import ocorrencias from './occurrences'
import studios from './studios'
import operators from './operators'
import recordings from './recordings'

export default [{
  title: 'Audiovisual',
  icon: 'ActivityIcon',
  tagVariant: 'light-warning',
  main: [
    ...demandsaudiovisual,
    ...ocorrencias,
    ...studios,
    ...operators,
    ...recordings
  ]
}]