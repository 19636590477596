export default [{
  path: '/pabx/agents',
  name: 'pabx-agents',
  component: () => import('@/views/apps/pabx/agents/index.vue'),
},
{
  path: '/pabx/queues',
  name: 'pabx-queues',
  component: () => import('@/views/apps/pabx/queue/index.vue'),
},
{
  path: '/pabx/queue/:id',
  name: 'pabx-queue-agents',
  component: () => import('@/views/apps/pabx/queue/queue-list.vue'),
},
{
  path: '/pabx/callback',
  name: 'callback-queue',
  component: () => import('@/views/apps/pabx/callback/index.vue'),
}]