export default {
    namespaced: true,
    state: {
      text: 'Enviando...',
      value: 0,
      total: 100
    },
    getters: {},
    mutations: {
      UPDATE_VALUE(state, payload) {
        state.value = payload;
      },
      UPDATE_LABEL(state, payload) {
        state.text = payload;
      }
    },
    actions: {}
  };