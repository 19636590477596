export default {
  namespaced: true,
  state: {
    dateSelected: null,
    operatorSelected: null,
    workshiftSelected: null,
    searchSubject: "",
    viewListMode: false,
    countReworks: 0,
    countPendencies: 0
  },
  getters: {},
  mutations: {
    UPDATE_OPERATOR_SELECTED(state, payload) {
      state.operatorSelected = payload;
    },
    UPDATE_WORKSHIFT_SELECTED(state, payload) {
      state.workshiftSelected = payload;
    },
    UPDATE_DATE_SELECTED(state, payload) {
      state.dateSelected = payload;
    },
    UPDATE_SEARCH_SUBJECT(state, payload) {
      state.searchSubject = payload;
    },
    UPDATE_VIEW_MODE(state, _payload) {
      state.viewListMode = !state.viewListMode;
    },
    UPDATE_COUNT_REWORKS(state, payload) {
      state.countReworks = payload;
    },
    UPDATE_COUNT_PENDENCIES(state, payload) {
      state.countPendencies = payload;
    }
  },
  actions: {},
};
