export default [
    {
        path: '/attendance/moderators',
        name: 'attendance-moderators',
        component: () => import('@/views/apps/attendance/moderators/index.vue'),
      },
      {
          path: '/attendance/moderators-scale',
          name: 'attendance-moderators-scale',
          component: () => import('@/views/apps/attendance/moderators/moderators-scale.vue'),
      },    
    ]