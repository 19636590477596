// questões > repetidas
export default {
  namespaced: true,
  state: {
    questionID: null,
    appointmentID: null,
    teacherSelected: null,
    institutionSelected: null
  },
  getters: {},
  mutations: {
    UPDATE_QUESTION_ID(state, payload) {
      state.questionID = payload;
    },
    UPDATE_APPOINTMENT_ID(state, payload) {
      state.appointmentID = payload;
    },
    UPDATE_SELECTED_TEACHER(state, payload) {
      state.teacherSelected = payload;
    },
    UPDATE_SELECTED_INSTITUTION(state, payload) {
      state.institutionSelected = payload;
    }
  },
  actions: {}
};
