export default {
    namespaced: true,
    state: {
      videoId: 0,
      videoElement: null,
      subjectValidate: [],
      subjectError: false
    },
    getters: {},
    mutations: {
      UPDATE_CURRENT_VIDEO(state, payload) {
        state.videoId = payload.videoId;
        state.videoElement = payload.videoElement;
      },
      UPDATE_CURRENT_TIME(state, payload) {
        state.videoElement.pip.currentTime = payload;
      },
      UPDATE_CURRENT_VOL(state, payload) {
        state.videoElement.pip.currentVol = payload;
      },
      SUBJECT_VALIDATE(state, payload) {
        if (state.subjectValidate.some((sub) => sub.index == payload.index)) {
          let i = state.subjectValidate.findIndex((sub) => sub.index == payload.index);
          state.subjectValidate[i] = payload;
        } else {
          state.subjectValidate.push(payload);
        }
        state.subjectError = state.subjectValidate.some((sub) => sub.validate)
      },
      SUBJECT_VALIDATE_RESET(state, payload) {
        state.subjectValidate = [];
        state.subjectError = false;
      }
    },
    actions: {}
  };