// audiovisual > gravações
export default {
  namespaced: true,
  state: {
    recordID: null,
    startDate: null,
    endDate: null,
    teacherSelected: null,
    projectSelected: null,
    companySelected: null,
    eventSelectes: null,
    courseSelected: null,
    statusSelected: null
  },
  getters: {},
  mutations: {
    UPDATE_RECORD_ID(state, payload) {
      state.recordID = payload;
    },
    UPDATE_SELECTED_STARTDATE(state, payload) {
      state.startDate = payload;
    },
    UPDATE_SELECTED_ENDDATE(state, payload) {
      state.endDate = payload;
    },
    UPDATE_SELECTED_TEACHER(state, payload) {
      state.teacherSelected = payload;
    },
    UPDATE_SELECTED_PROJECT(state, payload) {
      state.projectSelected = payload;
    },
    UPDATE_SELECTED_COMPANY(state, payload) {
      state.companySelected = payload;
    },
    UPDATE_SELECTED_EVENT(state, payload) {
      state.eventSelectes = payload;
    },
    UPDATE_SELECTED_COURSE(state, payload) {
      state.courseSelected = payload;
    },
    UPDATE_SELECTED_STATUS(state, payload) {
      state.statusSelected = payload;
    },
    UPDATE_SELECTED_MEDIA_TYPE(state, payload) {
      state.mediaTypeSelected = payload;
    }
  },
  actions: {}
};
