<template>
  <div v-if="$utils.isPermited(permission)">
    <b-overlay :show="busy" spinner-variant="primary" spinner-type="grow" :variant="skin">
      <slot></slot>
    </b-overlay>
  </div>
  <span v-else>-</span>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  props: {
    permission: {
      type: String,
      required: true,
    },
    busy: {
      type: Boolean,
      required: false,
    },
  },
};
</script>