export default [{
  title: 'Moderadores',
  icon: 'UsersIcon',
  tagVariant: 'light-warning',
  child: [
    {
      title: 'Moderadores',
      route: 'attendance-moderators',
      icon: "UsersIcon",
      roles: ['permission.appointment.studio.moderator.view']
    },
    {
      title: 'Esc. de Moderadores',
      route: 'attendance-moderators-scale',
      roles: ['permission.appointment.studio.moderator.view']
    }
  ]
}]