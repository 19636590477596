import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authorizationUser from './authorization-user'

import authorize from './softphone/authorize'         // SOFTPHONE
import menu from './softphone/menu'                   // SOFTPHONE
import call from './softphone/call'                   // SOFTPHONE
import login from './softphone/login'                 // SOFTPHONE
import toolbar from './tool-bar'                      // TOOLBAR
import recordingFilter from './filter/recording'      // FILTER
import linksFilter from './filter/links'              // FILTER
import repeatedFilter from './filter/repeated'        // FILTER
import downloads from './downloads'                   // DOWNLOADS DROPDOWN

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authorize,
    menu,
    call,
    login,
    authorizationUser,
    toolbar,
    recordingFilter,
    linksFilter,
    repeatedFilter,
    downloads
  },
  strict: process.env.DEV,
})
