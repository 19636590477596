export default [{
  path: '/uptos3/view',
  name: 'uptos3-view',
  component: () => import('@/views/apps/uptos3/view.vue'),
},
{
  path: '/uptos3/view/for-company/:company',
  name: 'uptos3-view-company',
  component: () => import('@/views/apps/uptos3/view.vue'),
},
{
  path: '/uptos3/questions/:id',
  name: 'uptos3-view-question',
  component: () => import('@/views/apps/uptos3/videos/uptos3-video.vue'),
},
{
  path: '/question/replace',
  name: 'question-view-replace',
  component: () => import('@/views/apps/uptos3/replace-question/question-control-replace.vue'),
}]