const enums = {
  presential: 1,
  remote: 2,
  call: 3,
  sala_vip: 4,
  broadcast_only: 5
}

export {
  enums
}