import softphone from './softphone'
import dialer from './dialer'
import pabx from './pabx'
import monitoring from './monitoring'
import moderators from './moderators'

export default [{
  title: 'Atendimento',
  icon: 'SlackIcon',
  tagVariant: 'light-warning',
  main: [
    ...moderators,
    ...dialer,
    ...pabx,
    ...softphone,
    ...monitoring,
  ]
}]