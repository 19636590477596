let _vm = null
import { masked } from './masked'
import { enums } from './enums'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    masked,
    enums,
    isPermited(_permission) {
        return this.getRoles().some(s => s === _permission)
    },
    getNavMenus() {
        return JSON.parse(localStorage.getItem("userData")).navMenus;
    },
    getRoles() {
        return JSON.parse(localStorage.getItem("userData")).roles;
    },
    populardropdown(_list, _texto, _valor, _btodos, _bnenhum) {
        const retorno = [];
        if (_btodos) {
            retorno.push({ label: "Todos", value: "-1" });
        }
        if (_bnenhum) {
            retorno.push({ label: "Nenhum", value: "-2" });
        }

        retorno.push(
            ..._list.map((m) => {
                return {
                    label: m[_texto],
                    value: m[_valor],
                };
            })
        );
        return retorno;
    },
    pagination(lst, _size, paginationDefault = 20) {
        if (lst && lst.length >= _size) {
            return paginationDefault
        }
        else
            return 0;
    },
    paginationSize() {
        const paginationDefault = 30;
        return paginationDefault;
    },
    getValueSelected(_vl) {
        return _vl.value !== "-3" ? _vl.value : null;
    },
    formatToNumber(number) {
        return parseInt(number)
    },
    formatToDate(date) {
        return `${date.getFullYear()}-${this.addZero(date.getMonth() + 1).toString()}-${this.addZero(date.getDate().toString())}`;
    },
    addZero(numero) {
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    },
    toast(title, msg) {
        this._toast({
            title,
            msg
        });
    },
    toastError(title, msg) {
        this._toast({
            variant: 'danger',
            icon: "BellIcon",
            title,
            msg,
        });
    },
    toastWarning(title, msg) {
        this._toast({
            variant: 'warning',
            icon: "InfoIcon",
            title,
            msg,
        });
    },
    _toast(payload) {
        _vm.$toast({
            component: ToastificationContent,
            props: {
                title: payload.title || "Notificação",
                icon: payload.icon || "CheckIcon",
                text: payload.msg || "Sucesso",
                variant: payload.variant || "success",
            },
        }, { position: payload.position || "top-center" });
    },
    getDateNow() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return `${yyyy}-${mm}-${dd}`;
    },

    getStates() {
        return [{ value: "AC", label: "Acre - AC" },
        { value: "AL", label: "Alagoas - AL" },
        { value: "AP", label: "Amapá - AP" },
        { value: "AM", label: "Amazonas - AM" },
        { value: "BA", label: "Bahia - BA" },
        { value: "CE", label: "Ceará - CE" },
        { value: "DF", label: "Distrito Federal - DF" },
        { value: "ES", label: "Espírito Santo - ES" },
        { value: "GO", label: "Goiás - GO" },
        { value: "MA", label: "Maranhão - MA" },
        { value: "MT", label: "Mato Grosso - MT" },
        { value: "MS", label: "Mato Grosso do Sul - MS" },
        { value: "MG", label: "Minas Gerais - MG" },
        { value: "PA", label: "Pará - PA" },
        { value: "PB", label: "Paraíba - PB" },
        { value: "PR", label: "Paraná - PR" },
        { value: "PE", label: "Pernambuco - PE" },
        { value: "PI", label: "Piauí - PI" },
        { value: "RJ", label: "Rio de Janeiro - RJ" },
        { value: "RN", label: "Rio Grande do Norte - RN" },
        { value: "RS", label: "Rio Grande do Sul - RS" },
        { value: "RO", label: "Rondônia - RO" },
        { value: "RR", label: "Roraima - RR" },
        { value: "SC", label: "Santa Catarina - SC" },
        { value: "SP", label: "São Paulo - SP" },
        { value: "SE", label: "Sergipe - SE" },
        { value: "TO", label: "Tocantins - TO" }];
    },
    clearExcel(str) {
        str = this.removeAccents(str).toLowerCase().trim();
        str = str.replace(".", "").replace(".", "").replace(".", "").replace(".", "").replace(".", "").replace(".", "").replace(".", "");
        str = str.replace("/", "").replace("/", "").replace("/", "").replace("/", "").replace("/", "").replace("/", "").replace("/", "");
        str = str.replace(" ", "_").replace(" ", "_").replace(" ", "_").replace(" ", "_").replace(" ", "_").replace(" ", "_");
        return str;
    },
    isPhone(str) {
        return str.includes('phone') || str.includes('telefone') || str.includes('fone');

    },
    removeAccents(str) {
        return str ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : str
    },
    formatCurrencyToBR(number) {
        return parseFloat(number).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL'
        });
    },
    formatCurrencyToDouble(number) {
        let num = this.formatCurrencyToBR(number);
        num = num.replace("R$", "")
        num = num.replace(".", "")
        num = num.replace(",", ".")
        return parseFloat(num.trim())
    },
    getGuidId() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    },
    reverseDate(_date) {
        return _date.split('T')[0].split('-').reverse().join('/')
    },
    getHour(_date) {
        return _date.split('T')[1];
    },
    getPayloadAppointment(appointmentDTO, steps) {
        let payload = {
            ...appointmentDTO,
            job_type_id: appointmentDTO.jobTypeSelected.value,
            demand_audio_visual: appointmentDTO.demandAudioVisual,
            transmission_type: appointmentDTO.transmission_type,
        };

        if (appointmentDTO.jobTypeProductsSelected) {
            payload.appointmentProducts =
                appointmentDTO.jobTypeProductsSelected.map((m) => {
                    return {
                        product_id: m.value,
                        product: {
                            name: m.label,
                        },
                    };
                });
        }

        // default
        payload.is_streaming = false;
        if (appointmentDTO.jobTypeSelected.jobProducts.length === 1) {
            // verifica se e apenas transmissão
            payload.is_streaming =
                appointmentDTO.jobTypeSelected.jobProducts.some(
                    (s) => s.products.name.toLowerCase() === "somente transmissão"
                );
        }

        // if (appointmentDTO.jobTypeSelected.jobFormats.length === 1) {
        //     // verifica se e apenas transmissão
        //     payload.is_streaming =
        //         !appointmentDTO.jobTypeSelected.jobFormats.some(
        //             (s) => s.formatType.name.toLowerCase() === "sala vip"
        //         );
        // } else {
        // 1=evento, 2=aula
        if (payload.type === 1) {
            // se evento for todo gravado, marcar como stream
            payload.is_streaming = payload.recording_all_events;
        } else {
            // se evento for todo gravado, marcar como stream
            payload.is_streaming = payload.accept_recording;
        }

        //  nova regra is_streaming
        if (appointmentDTO.jobTypeSelected.jobFormats.length === 1) {
            // jobtype requer apenas transmissão.
            if (
                appointmentDTO.jobTypeSelected.jobFormats.some(
                    (s) => s.formatType.id === enums.broadcast_only
                )
            ) {
                // homeoffice = false, e transmissão true.
                payload.is_streaming = true;
                payload.is_home_office = false;
            }
        }
        //}

        // Estúdio não visivel, então deve ser informado estudio homeoffice automaticamente.
        if (!steps.filter((f) => f.index === 4)[0].visible) {
            if (!payload.is_home_office) {
                payload.studios = [];
                //payload.is_home_office = false;
            }
        } else if (!steps.filter((f) => f.index === 5)[0].visible) {
            // estudio de apoio não visivel remover do payload.
            payload.studios = payload.studios.filter((f) => f.main);
        }

        // 1 - evento, 2 = aula
        if (appointmentDTO.type === 1) {
            // 'evento todo gravado?' >> não marcado
            // 'tem gravações?' >> não marcado
            if (
                !appointmentDTO.recording_all_events &&
                !appointmentDTO.accept_recording
            ) {
                // Limpar os objeto de gravações.
                payload.appointmentSubjects = [];
            }
        }

        return payload

    },

    install(Vue, config) {
        _vm = config.vm
        Vue.prototype.$utils = this
    },
    vue() {
        return _vm
    },
}