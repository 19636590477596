<template>
  <b-overlay :show="busy" spinner-variant="primary" spinner-type="grow" :variant="skin">
    <b-card>
      <template #header>
        <h4 style="width: 90%" class="mb-0">
          <span
            class="text-truncate mr-25"
            style="display: inline-block"
            :style="title2 ? { maxWidth: '49%' } : { maxWidth: '100%' }"
          >
            {{ title }}
          </span>
          <span
            v-if="title2"
            class="text-truncate"
            style="display: inline-block; max-width: 49%"
          >
            &#10095; {{ title2 }}
          </span>
          <small v-if="subtitle" class="d-block">{{ subtitle }}</small>
        </h4>
        <b-row v-if="btnew || btsave || btdelete || btback || btnewevent">
          <b-col cols="12">
            <!-- NOVO REGISTRO -->
            <button--c
              :variant="btnew.variant || 'info btn-icon'"
              :iconsize="btnew.iconsize || '20'"
              :icon="btnew.icon || 'PlusIcon'"
              :permission="btnew.permission"
              :title="btnew.title || ''"
              :to="btnew.to"
              class="mr-1"
              v-if="btnew"
            />

            <!-- SAVE -->
            <button--c
              :variant="btsave.variant || 'info btn-icon'"
              :iconsize="btsave.iconsize || '20'"
              :icon="btsave.icon || 'SaveIcon'"
              :permission="btsave.permission"
              :title="btsave.title || ''"
              @clicked="onclicksave"
              v-if="btsave"
              class="mr-1"
            />

            <!-- DELETE -->
            <button--c
              :variant="btdelete.variant || 'danger btn-icon'"
              :iconsize="btdelete.iconsize || '20'"
              :icon="btdelete.icon || 'Trash2Icon'"
              :permission="btdelete.permission"
              :title="btdelete.title || ''"
              @clicked="onclickdelete"
              v-if="btdelete"
              class="mr-1"
            />

            <button--c
              :variant="'secondary'"
              :iconsize="'20'"
              :icon="'ArrowLeftIcon'"
              :title="''"
              @clicked="onclickGo"
              :permission="btback.permission"
              v-if="btback && btback.permission && btback.click"
              class="btn-icon"
            />
            <b-button
              :style="btback.style ? btback.style : null"
              @click="$router.go(-1)"
              variant="secondary"
              class="btn-icon"
              v-if="btback && !btback.permission"
            >
              <feather-icon icon="ArrowLeftIcon" size="20" />
            </b-button>

            <!-- NOVO REGISTRO COM EVENTO -->
            <button--c
              :variant="btnewevent.variant || 'info btn-icon'"
              :iconsize="btnewevent.iconsize || '20'"
              :icon="btnewevent.icon || 'PlusIcon'"
              :permission="btnewevent.permission"
              :title="btnewevent.title || ''"
              class="mr-1"
              v-if="btnewevent"
              @clicked="onclickGoNewEvent"
            />
          </b-col>
        </b-row>
      </template>

      <!-- conteudo -->
      <slot></slot>
    </b-card>
  </b-overlay>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import button from "@/components/button.vue";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    "button--c": button,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    title2: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    busy: {
      type: Boolean,
      required: false,
    },
    btnew: {
      type: Object,
      required: false,
    },
    btsave: {
      type: Object,
      required: false,
    },
    btdelete: {
      type: Object,
      required: false,
    },
    btback: {
      type: Object,
      required: false,
    },
    btnewevent: {
      type: Object,
      required: false,
    },
  },
  methods: {
    onclicksave() {
      this.$emit("clicked-save");
    },
    onclickdelete() {
      this.$emit("clicked-delete");
    },
    onclickGo() {
      this.$emit("clicked-go");
    },
    onclickGoNewEvent() {
      this.$emit("clicked-new-event");
    },
  },
};
</script>