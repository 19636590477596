export default [{
  path: '/audio-visual/operators',
  name: 'audiovisual-operators',
  component: () => import('@/views/apps/audiovisual/operators/index.vue'),
},
{
  path: '/audio-visual/operator/:id',
  name: 'audiovisual-operator-save',
  component: () => import('@/views/apps/audiovisual/operators/operator.vue'),
},
{
  path: '/audio-visual/studiosgroup',
  name: 'audiovisual-studiosgroup',
  component: () => import('@/views/apps/audiovisual/grupostudios/index.vue'),
},
{
  path: '/audio-visual/studiogroup/:id',
  name: 'audiovisual-studios-save',
  component: () => import('@/views/apps/audiovisual/grupostudios/grupostudio.vue'),
},
{
  path: '/audio-visual/upload',
  name: 'audiovisual-upload-list',
  component: () => import('@/views/apps/audiovisual/recording/index.vue'),
},
{
  path: '/audio-visual/upload/teacher-view/:vertical',
  name: 'audiovisual-upload-list',
  component: () => import('@/views/apps/audiovisual/recording/index.vue'),
  meta: {
    layout: 'full',
  }
},
{
  path: '/audiovisual/recording/new/:teacher',
  name: 'audiovisual-recording-new',
  component: () => import('@/views/apps/audiovisual/recording/upload-teacher/index.vue'),
},
{
  path: '/audiovisual/recording/view/:id/:subject?',
  name: 'audiovisual-recording-view',
  component: () => import('@/views/apps/audiovisual/recording/recording.vue'),
},
{
  path: '/audiovisual/recording/edit/:id',
  name: 'audiovisual-recording-edit',
  component: () => import('@/views/apps/audiovisual/recording/upload-teacher/index.vue'),
},
{
  path: '/audiovisual/recording/clone/:id',
  name: 'audiovisual-recording-clone',
  component: () => import('@/views/apps/audiovisual/recording/recording-clone.vue'),
},
{
  path: '/audio-visual/studios/:studiogroupid',
  name: 'audiovisual-studios',
  component: () => import('@/views/apps/audiovisual/studios/index.vue'),
  meta: {
    breadcrumb: [
      {
        text: 'Grupo Estudios',
        back: true,
        backto: -1,
      },
      {
        text: 'Estudios',
        active: true
      },
    ]
  }
},
{
  path: '/audio-visual/operators-scale',
  name: 'audiovisual-operators-scale',
  component: () => import('@/views/apps/audiovisual/scaleoperators/operators-scale.vue'),
},
// {
//   path: '/audio-visual/operators/appointment',
//   name: 'audiovisual-operators-appointment',
//   component: () => import('@/views/apps/audiovisual/appointment/index.vue'),
// },
{
  path: '/audio-visual/operators/appointment/operation',
  name: 'audiovisual-operators-appointment-operation',
  component: () => import('@/views/apps/audiovisual/operation/index.vue'),
},
{
  path: '/audio-visual/recording/upload/operator',
  name: 'audiovisual-recording-upload-operator',
  component: () => import('@/views/apps/audiovisual/recording/upload-teacher/operator.vue'),
},
{
  path: '/audio-visual/demands/products',
  name: 'audiovisual-demands-products',
  component: () => import('@/views/apps/audiovisual/demands/products/index.vue'),
},
{
  path: '/audio-visual/demands/products/:id',
  name: 'audiovisual-demands-products-save',
  component: () => import('@/views/apps/audiovisual/demands/products/product.vue'),
},
{
  path: '/audio-visual/demands/format-type',
  name: 'audiovisual-demands-format-type',
  component: () => import('@/views/apps/audiovisual/demands/formats/index.vue'),
},
{
  path: '/audio-visual/demands/format-type/:id',
  name: 'audiovisual-demands-format-type-save',
  component: () => import('@/views/apps/audiovisual/demands/formats/format.vue'),
},
{
  path: '/audio-visual/demands/period-type',
  name: 'audiovisual-demands-period-type',
  component: () => import('@/views/apps/audiovisual/demands/periods/index.vue'),
},
{
  path: '/audio-visual/demands/period-type/:id',
  name: 'audiovisual-demands-period-type-save',
  component: () => import('@/views/apps/audiovisual/demands/periods/period.vue'),
},
{
  path: '/audio-visual/demands/transmission-type',
  name: 'audiovisual-demands-transmission-type',
  component: () => import('@/views/apps/audiovisual/demands/transmissions/index.vue'),
},
{
  path: '/audio-visual/demands/transmission-type/:id',
  name: 'audiovisual-demands-transmission-type-save',
  component: () => import('@/views/apps/audiovisual/demands/transmissions/transmission.vue'),
},
{
  path: '/audio-visual/demands/job-type',
  name: 'audiovisual-demands-job-type',
  component: () => import('@/views/apps/audiovisual/demands/jobtype/index.vue'),
},
{
  path: '/audio-visual/demands/job-type/:id',
  name: 'audiovisual-demands-job-type-save',
  component: () => import('@/views/apps/audiovisual/demands/jobtype/jobstype.vue'),
},
{
  path: '/audio-visual/demands/checklist',
  name: 'audiovisual-demands-checklist',
  component: () => import('@/views/apps/audiovisual/demands/checklist/index.vue'),
},
{
  path: '/audio-visual/demands/checklist/:id',
  name: 'audiovisual-demands-checklist-save',
  component: () => import('@/views/apps/audiovisual/demands/checklist/checklist.vue'),
},
{
  path: '/audio-visual/demands/questions',
  name: 'audiovisual-demands-questions',
  component: () => import('@/views/apps/audiovisual/demands/questions/index.vue'),
},
{
  path: '/audio-visual/demands/questions/:id',
  name: 'audiovisual-demands-questions-save',
  component: () => import('@/views/apps/audiovisual/demands/questions/question.vue'),
},
{
  path: '/audio-visual/occurrence/job-occurrence',
  name: 'audiovisual-occurrence-job-ocurrence',
  component: () => import('@/views/apps/audiovisual/occurrence/job-occurrence/index.vue'),
},
{
  path: '/audio-visual/occurrence/job-occurrence/:id',
  name: 'audiovisual-occurrence-job-ocurrence-save',
  component: () => import('@/views/apps/audiovisual/occurrence/job-occurrence/job-occurrence.vue'),
},
{
  path: '/audio-visual/occurrence/occurrence-entity',
  name: 'audiovisual-occurrence-entity',
  component: () => import('@/views/apps/audiovisual/occurrence/occurrence-entity/index.vue'),
},
{
  path: '/audio-visual/occurrence/occurrence-entity/:id',
  name: 'audiovisual-occurrence-entity-save',
  component: () => import('@/views/apps/audiovisual/occurrence/occurrence-entity/occurrence-entity.vue'),
},
{
  path: '/audio-visual/occurrence/occurrence-category',
  name: 'audiovisual-occurrence-category',
  component: () => import('@/views/apps/audiovisual/occurrence/occurrence-category/index.vue'),
},
{
  path: '/audio-visual/occurrence/occurrence-category/:id',
  name: 'audiovisual-occurrence-category-save',
  component: () => import('@/views/apps/audiovisual/occurrence/occurrence-category/occurrence-category.vue')
},
{
  path: '/audio-visual/recoding-pending',
  name: 'audiovisual-recording-pending-list',
  component: () => import('@/views/apps/audiovisual/recording-pending/index.vue')
},
{
  path: '/audio-visual/recoding-pending/:id',
  name: 'audiovisual-recording-pending-save',
  component: () => import('@/views/apps/audiovisual/recording-pending/edit.vue')
},
{
  path: '/audio-visual/recoding-pending/view/:id',
  name: 'audiovisual-recording-pending-view',
  component: () => import('@/views/apps/audiovisual/recording-pending/view.vue')
},
{
  path: '/audio-visual/demands/work-shift/:id',
  name: 'audiovisual-demands-work-shift-save',
  component: () => import('@/views/apps/audiovisual/demands/workshift/workshift.vue'),
},
{
  path: '/audio-visual/demands/work-shift',
  name: 'audiovisual-demands-work-shift',
  component: () => import('@/views/apps/audiovisual/demands/workshift/index.vue'),
},
{
  path: '/audio-visual/recoding-edit',
  name: 'audiovisual-recording-edit-list',
  component: () => import('@/views/apps/audiovisual/recording-edit/index.vue')
},
{
  path: '/audio-visual/validate-upload',
  name: 'audiovisual-validate-upload',
  component: () => import('@/views/apps/audiovisual/validate-upload/index.vue')
}]