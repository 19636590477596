<template>
  <div>
    <!-- Você pode adicionar uma mensagem de carregamento ou qualquer outro conteúdo -->
    <p>Processando autenticação...</p>
  </div>
</template>

<script>
import _authService from "@/services/auth-service";
import rotas from "@/navigation/vertical/index";
import useJwt from "@/auth/jwt/useJwt";

export default {
  name: 'AuthCallback',
  mounted() {
    this.processAuthCallback();
  },
  methods: {
    processAuthCallback() {
      const encodedAuthData = new URL(window.location).hash.split('authData=')[1];

      console.log('encodedAuthData', encodedAuthData);

      if (encodedAuthData) {

        // Decodifica a string URL
        const urlDecoded = decodeURIComponent(encodedAuthData);

        console.log(urlDecoded);

        // Decodifica a string Base64
        const base64Decoded = atob(urlDecoded);

        console.log(base64Decoded);

        // Converte a string JSON de volta para um objeto
        const jsonResponse = JSON.parse(base64Decoded);

        console.log(jsonResponse);

        this.loginSuccess(jsonResponse);
      } else {
        // Se não houver dados de autenticação, redirecione para a página de login
        this.$router.push({ name: 'auth-login' });
      }
    },
    loginSuccess(data) {
      const res = data; // Supondo que os dados são enviados no formato correto
      useJwt.setToken(res.content.accessToken);
      useJwt.setRefreshToken(res.content.refreshToken);

      // Rotas e menus
      this.getrotas();
      const _navMenus = this.getMenus(res.content.data);
      res.content.data.navMenus = _navMenus;
      localStorage.setItem("userData", JSON.stringify(res.content.data));
      this.$ability.update([{ action: "manage", subject: "all" }]);

      // Redirect by query
      if (this.$route.query.redirect) {
        const redirect_url = this.$route.query.redirect.replace(/_/g, "/");
        this.$router.push({ path: `/${redirect_url}` }).then(() => {
          // Recarrega a página após redirecionar
          window.location.reload();
        });
        return;
      }

      // Redirect
      if (this.$route.params.redirect) {
        const redirect_url = this.$route.params.redirect.replace(/_/g, "/");
        this.$router.push({ path: `/${redirect_url}` }).then(() => {
          // Recarrega a página após redirecionar
          window.location.reload();
        });
      } else {
        this.$router.push({ name: "dashboard" }).then(() => {
          // Recarrega a página após redirecionar
          window.location.reload();
        });
      }
    },
    getrotas() {
      let rotasR = [];
      if (rotas) {
        rotas.forEach((_row) => {
          if (_row.roles) {
            rotasR.push({ route: _row.route, name: _row.roles.join(", ") });
          }

          if (_row.children) {
            _row.children.forEach((_cchild) => {
              if (_cchild.roles) {
                rotasR.push({
                  route: _cchild.route,
                  name: _cchild.roles.join(", "),
                });
              }
            });
          }

          if (_row.main) {
            _row.main.forEach((_main) => {
              if (_main.roles) {
                rotasR.push({
                  route: _main.route,
                  name: _main.roles.join(", "),
                });
              }

              if (_main.child) {
                _main.child.forEach((_cchild) => {
                  rotasR.push({
                    route: _cchild.route,
                    name: _cchild.roles.join(", "),
                  });
                });
              }
            });
          }
        });
      }
      return rotasR;
    },
    getMenus(userData) {
      var _rrotas = JSON.parse(JSON.stringify(rotas));
      _rrotas.forEach((menu) => {
        if (menu.title === "Relatórios") {
          if (userData.reports && userData.reports.length > 0) {
            userData.reports.forEach((_REPORT) => {
              menu.children.push({
                title: _REPORT.label,
                target: "_self",
                href: `/reports-dynamic/${_REPORT.code}`,
                icon: "BatteryChargingIcon",
                roles: [_REPORT.value],
              });
            });
          }
        }

        menu.active = false;
        if (menu.main) {
          menu.children = [];
          menu.main.forEach((_main) => {
            if (_main.child) {
              // validar CHILD

              _main.child.forEach((_childen) => {
                _childen.active = _childen.roles === null;
                if (!_childen.active) {
                  if (_childen.roles && _childen.roles.length > 0) {
                    _childen.active = _childen.roles.some((srule) =>
                      userData.roles.some((s) => s === srule)
                    );
                  }
                }
              });
              // validar CHILD

              //
              _main.children = _main.child.filter((f) => f.active);
              _main.active = _main.children.some((f) => f.active);
              if (_main.active) {
                menu.children.push(_main);
                menu.active = true;
              }
            } else if (_main.roles) {
              _main.roles.forEach((roleMenu) => {
                if (!_main.active) {
                  _main.active = userData.roles.some((s) => s === roleMenu);
                  if (_main.active) {
                    menu.children.push(_main);
                    menu.active = true;
                  }
                }
              });
            }
          });
        } else if (menu.children) {
          menu.children.forEach((_childen) => {
            _childen.active = _childen.roles === null;
            if (!_childen.active) {
              if (_childen.roles && _childen.roles.length > 0) {
                _childen.active = _childen.roles.some((srule) =>
                  userData.roles.some((s) => s === srule)
                );
              }
            }
          });

          // apenas menus com permissão
          menu.children = menu.children.filter((f) => f.active);
          menu.active = menu.children.some((f) => f.active);
        } else if (menu.roles) {
          menu.roles.forEach((roleMenu) => {
            if (!menu.active) {
              menu.active = userData.roles.some((s) => s === roleMenu);
            }
          });
        }
      });
      return _rrotas;
    },
  }
}
</script>

<style scoped>
/* Adicione seus estilos aqui, se necessário */
</style>