import videoPlayer from './video-player';
import progress from './progress';
import operation from './operation';
import operatiorbar from './operatorbar';
import validateUpload from './validate-upload';

export default {
  namespaced: true,
  state: {
    currentToolBar: 'none',
  },
  getters: {
    toolBarShow(state) {
      return state.currentToolBar == 'none' ? false : true;
    }
  },
  mutations: {
    UPDATE_CURRENT_TOOLBAR(state, payload) {
      state.currentToolBar = payload;
    }
  },
  actions: {},
  modules: {
    progress,
    videoPlayer,
    operation,
    operatiorbar,
    validateUpload
  }
};