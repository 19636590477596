export default [{
  title: 'Relatórios',
  icon: 'DatabaseIcon',
  tagVariant: 'light-warning',
  children: [{
    title: 'Acomp. Individual',
    route: 'reports-attendance-goal',
    icon: "BatteryChargingIcon",
    roles: ['permission.reports.goal.view']
  }]
}]