export default [{
  title: 'Questões',
  icon: 'TargetIcon',
  tagVariant: 'light-warning',
  children: [
    {
      title: 'Vínculos',
      route: 'uptos3-view',
      icon: "RepeatIcon",
      roles: ['permission.question.vinculos.view']
    },
    {
      title: 'Alterar Questão',
      route: 'question-view-replace',
      icon: "ShareIcon",
      roles: ['permission.question.media.replace_question']
    }
  ]
}]