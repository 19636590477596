export default {
  namespaced: true,
  state: {
    show: false,
    downloadItems: [],
  },
  getters: {},
  mutations: {
    NEW_DOWNLOAD_ITEM(state, payload) {
      state.downloadItems.push({
        url_media: payload.url_media,
        original_filename: payload.original_filename,
        progress: 0
      });
    },
    ITEM_PROGRESS_UPDATE(state, payload) {
      let index = state.downloadItems.findIndex((item) => item.url_media == payload.url_media);
      state.downloadItems[index].progress = payload.progress;
    },
    DEL_DOWNLOAD_ITEM(state, payload) {
      state.downloadItems = state.downloadItems.filter((f) => f.url_media != payload)
    },
    SHOWING(state, payload) {
      state.show = payload;
    }
  },
  actions: {}
};
