export default [{
  title: 'Operadores',
  icon: 'UsersIcon',
  tagVariant: 'light-warning',
  child: [
    {
      title: 'Operadores',
      route: 'audiovisual-operators',
      icon: "UsersIcon",
      roles: ['permission.operators.view']
    },
    {
      title: 'Esc. de Operadores',
      route: 'audiovisual-operators-scale',
      roles: ['permission.appointment.studio.operator.view']
    },
    // {
    //   title: 'Meus Agendamentos',
    //   route: 'audiovisual-operators-appointment',
    //   icon: "BellIcon",
    //   roles: ['permission.appointment.operator.view']
    // },
    {
      title: 'Operação',
      route: 'audiovisual-operators-appointment-operation',
      icon: "BellIcon",
      roles: ['permission.appointment.operator.transfer']
    }
  ]
}]