export default [{
  path: '/create-teacher',
  name: 'accounts-teacher',
  component: () => import('@/views/pages/accounts/index.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
    redirectIfLoggedIn: true,
    notauthentication: true,
  },
}]
